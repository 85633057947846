import {
  saveStoryAngle,
  saveTalkingPoint,
} from "../../../../../redux/service/api/visitorApi";

export default async function saveStoryAngles(storyAngles) {
  const storyAnglesId = [];

  await Promise.all(
    storyAngles.map(async (storyAngle) => {
      const savedTalkingPoints = await Promise.all(
        storyAngle.talkingPoints.map(async (talkingPoint) => {
          const response = await saveTalkingPoint({ talkingPoint });
          return response._id;
        })
      );

      const response = await saveStoryAngle({
        headline: storyAngle.headline,
        selected: storyAngle.isSelected,
        story_angle_talking_points: savedTalkingPoints,
      });

      storyAnglesId.push(response._id);
    })
  );

  return storyAnglesId;
}
