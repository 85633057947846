import {
  saveSES,
  saveStakeholder,
  saveTarget,
} from "../../../../../redux/service/api/visitorApi";

async function saveAllSES(sesItems) {
  const response = await Promise.all(
    sesItems.map((item) => saveSES({ SES: item }))
  );
  return response.map((response) => response._id);
}

async function saveAllStakeholders(stakeholderItems) {
  const response = await Promise.all(
    stakeholderItems.map((item) => saveStakeholder({ stakeholder: item }))
  );
  return response.map((response) => response._id);
}

export default async function saveTargets(target) {
  const target_ses = await saveAllSES(target.ses);
  const target_stakeholders = await saveAllStakeholders(target.stakeholders);

  const response = await saveTarget({
    age: target.age,
    sex: target.sex,
    target_ses,
    target_stakeholders,
  });

  return response;
}
