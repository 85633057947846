import apiStrapi from "./apiStrapi";

const responseApi = async (url, data) => {
  try {
    const response = await apiStrapi(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });

    return response.data;
  } catch (error) {
    console.error("Error saving talking point:", error);
    throw error;
  }
};

//

export const saveIdeationFeature = async (data) => {
  return responseApi("/ideation-features", data);
};

export const saveTalkingPoint = async (data) => {
  return responseApi("/story-angle-talking-points", data);
};

export const saveStoryAngle = async (data) => {
  return responseApi("/story-angles", data);
};

export const saveTarget = async (data) => {
  return responseApi("/targets", data);
};

export const saveSES = async (data) => {
  return responseApi("/target-ses", data);
};

export const saveStakeholder = async (data) => {
  return responseApi("/target-stakeholders", data);
};

export const saveHeadline = async (data) => {
  return responseApi("/headlines", data);
};
